.gallery-div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    overflow: hidden;
    margin: 60px 0;
    .gallery-first-element {
        margin-left: -25px;
        img {
            height: 537px;
            width: auto;
        }
    }
    .gallery-second-element {
        display: flex;
        flex-direction: column;
        .upper-element {
            img {
                height: 264px;
                width: auto;
            }
        }
        .bottom-element {
            display: flex;
            flex-direction: row;
            .third-element {
                height: 263.46px;
                width: auto;
                margin-top: 32.23px;
                
            }
            .fourth-element {
                height: 263.46px;
                width: auto;
                margin-top: 28.93px;
                margin-left: 47.75px;
            }
        }
    }
    .gallery-third-element {
        img {
            height: 503.86px;
            width: auto;
        }
    }
    .gallery-fourth-element {
        .upper-element {
            img {
                height: 197.59px;
                width: auto;
                margin-bottom: 60px;
            }
        }
        .bottom-element {
            img {
                height: 263.46px;
                width: auto;
            }
        }
    }
}