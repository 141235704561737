.product-card {
    height: 535px;
    max-width: 380px;
    background-color: #FFFFFF;
    .product-image-wrapper {
        height: 254px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          cursor: pointer;
        }
    }
    .product-content {
        position: relative;
        padding: 18px 15px 0 15px;
        .product-title {
            height: 60px;
            max-width: 235px;
            color: #000000;
            font-size: 24px;
            letter-spacing: 0.28px;
            line-height: 30px;
        }
        .product-infos {
            height: 44px;
            max-width: 300px;
            color: #000000;
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 44px;
        }
        
        .product-price-div {
            height: 60px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            .euro {
                height: 42px;
                max-width: 18px;
                color: #1B2437;
                font-size: 24px;
                letter-spacing: 0;
                line-height: 29px;
                margin-right: 6px;
            }
            .product-price {
                height: 42px;
                max-width: 214px;
                color: #1B2437;
                font-size: 36px;
                letter-spacing: 0.4px;
                line-height: 29px;
            }
            .menge-type {
                height: 42px;
                max-width: 114px;
                color: #1B2437;
                font-size: 24px;
                letter-spacing: 0.4px;
                line-height: 29px;
            }
        }
    }
    .product-menge {
        position: relative;
        padding: 18px 15px 0 15px; 
        display: flex;
        flex-direction: row;
        align-items: center;
        .product-input {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flexstart;
            input {
                box-sizing: border-box;
                height: 42.9px;
                width: 110px;
                border: 2px solid #00223B;
                background-color: #FFFFFF;
                margin: 0 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }
            button {
                height: 28.93px;
                width: 28px;
                background-color: #1A1818;
                border: none !important;
                box-shadow: none !important;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 10px;
                }
            }
        }
        .product-basket-button {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            button {
                background-color: rgba(0,0,0,0.28);
                transition: all .5s ease-in-out;
                border: none !important;
                box-shadow: none !important;
                height: 42.9px;
                width: 46px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    height: 21px;
                    width: 22.81px;
                }
               
            }
            &.active{
                button {
                    background-color: rgba(0,0,0);
                }
            }
        }
    }
}