.type-text {
    height: 16px;
    color: rgba(0,0,0,0.54);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
}

.customized-text {
  height: 16px;
  width: 231px;
  color: rgba(0,0,0,0.54);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}

.payment-text {
    height: 50px;
    width: 89px;
    color: #00223B;
    font-size: 22px;
    letter-spacing: 0.26px;
    line-height: 49.13px;
}

.totalprice-text {
    height: 26px;
    width: 164px;
    color: rgba(0,0,0,0.87);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
}

.price {
    height: 48px;
    width: 140px;
    color: #00223B;
    font-size: 22px;
    letter-spacing: 0.26px;
    line-height: 49.13px;
}

.holzhandel-tabs {
    display: flex;
    justify-content: stretch;
    column-gap: 4px;
    width: 35%;
    margin: 0 0 20px 0;
  
    .holzhandel-tab {
        padding: 0;
        text-align: center;
        font-style: normal;
        text-align: center;
        background: transparent;
        flex: 1;

        height: 25px;
        width: 39px;
        color: #1B2437;
        font-family: "Playfair Display";
        font-size: 12px;
        
        letter-spacing: 0;
        line-height: 25px;
        position: relative;
        &.active {
            font-weight: bold;
            &:after {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                content: "";
                height: 7px;
                width: 7px;
                background-color: #00223B;
                display: block;
                margin: 5px 0 0 0;
                border-radius: 50%;
            }
        }

        &:not(.active):not(.disabled):hover {
            cursor: pointer;
        }
    }
}

.line {
    box-sizing: border-box;
    height: 2px;
    width: 35%;
    border: 1px solid #00223B;
}

.datepicker-arrow-controls {
  display: flex !important;
}

.holzhandel-profileform {
  	.submit-button-style {
      background-color: #000000 !important;
    }
    .white-panel {
      padding: 0 10px !important;
      color: black;
      .purchases-text {
        margin: 10px 0;
        span {
          display: flex;
          width: 70%;
          align-items: center;
          font-family: "Playfair Display";
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          line-height: 18px;
          text-align: left;
          color: #7b7474;
        }
        
      }
      span {
        display: flex;
        align-items: center;
        font-family: "Playfair Display";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        color: #7b7474;
      }
      .react-tel-input {
        margin: 6px 0 10px 0;
      }

      input {
        margin: 6px 0 10px 0;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        width: 70%;
        @media (max-width: 991.98px) {
          width: 100%;
          max-width: 400px;
        }
        height: 35px;
        color: black;
        position: relative;
        font-size: 14px;
        letter-spacing: .01rem;
        padding-left: 48px;
        margin-left: 0;
        background: #FFFFFF;
        border: 1px solid #CACACA;
        border-radius: 5px;
        line-height: 25px;
        outline: none;
        &:focus {
          color: black;
        }
      }
      .select-arrow {
        position: absolute;
        top: 14px;
        right: 20px;
      }
      .select-arrow::after {
        border-top: 0.7em solid;
        border-right: 0.5em solid transparent;
        border-left: 0.5em solid transparent;
      }
      .form-outline {
        width: 70%;
        .form-control ~ .form-label {
          display: none;
        }
        .form-control ~ .form-notch {
          display: none;
        }
        input {
          margin: 6px 0 10px 0;
          font-family: inherit;
          font-size: inherit;
          line-height: inherit;
          width: 100%;
          height: 35px;
          color: black;
          position: relative;
          font-size: 14px;
          letter-spacing: .01rem;
          padding-left: 48px;
          margin-left: 0;
          background: #FFFFFF;
          border: 1px solid #CACACA;
          border-radius: 5px;
          line-height: 25px;
          outline: none;
          &:focus {
            color: black;
          }
        }
        .datepicker-toggle-button {
          position: absolute;
          right: 10px;
          top: 17px;
          transform: translate(-50%, -50%);
        }
        
      }
      

      .toggle-with-label {
        display: flex;
        align-items: center;
        font-family: "Playfair Display";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        text-align: center;
        color: #000000;
      
        .jc-toggle {
          .react-toggle-track {
            height: 0;
          }
          .react-toggle-thumb {
            width: 20px;
          }
          margin-right: 10px;
          input {
            margin: 5px 0;
          }
        }
      
        label {
          text-align: left;
      
          a {
            color:  #c8866e!important;
            margin-left: 5px;
            font-weight: bold;
          }
      
          i {
            margin-left: 10px;
            color: #c8866e;
            font-size: 17px;
            line-height: 17px;
            cursor: pointer;
          }
        }
      
        &:not(.no-pointer) {
          cursor: pointer;
        }
      
        & + .toggle-with-label {
          margin-top: 15px;
        }
      }
  
      h3 {
        font-family: "Playfair Display";
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        line-height: 23px;
        color: #252626;
        margin-bottom: 20px;
      }
  
      .row {
        .col-md-5 {
          display: flex;
          align-items: center;
        }
  
        label {
          font-family: "Playfair Display";
          font-style: normal;
          font-weight: 700;
          font-size: 15px;
          line-height: 20px;
          color: #252626;
  
          a {
            color: #c8866e;
          }
  
          i {
            color: #c8866e;
            margin-left: 5px;
            cursor: pointer;
          }
        }
        & + .row {
          margin-top: 20px;
        }
      }
    }
}

.stripe-button {
  box-sizing: border-box;
  height: 45px;
  width: 140px;
  border-radius: 13.2px;
  background-color: transparent !important;
  padding:0;
  img {
    width: 140px;
  height: 45px;
  object-fit: cover;
  }
}
.pay-button {
  box-sizing: border-box;
  height: 42.9px;
  width: 140px;
  border: 1.65px solid #00223B;
  border-radius: 13.2px;
  background-color: #00223B !important;
  color: #FFEBD3;
  font-family: "Playfair Display";
  font-size: 18.15px;
  font-weight: bold;
  letter-spacing: 0.89px;
  text-align: center;
  padding:0;
}

.logo-mastercard {
  width: 48px;
  margin: 0 18px 0 0;
  padding: 0;
}

.visa-logo {
  width: 66px;
  padding: 0;
}

.jc-page.checkout-confirmation {
  .thank-you-panel {
    margin-bottom: 15px;
  
    .container-fluid {
      display: flex;
      justify-content: center;
      padding: 28px 20px;
      background: #c8866e;
      border-radius: 5px;
  
      img {
        max-width: 48px;
      }
  
      & > div {
        font-family: "Satoshi";
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #f9f9fa;
        margin-left: 20px;
  
        h1 {
          font-family: "Kaisei Decol";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 29px;
          color: #f9f9fa;
          margin-bottom: 4px;
        }
      }
    }
  }
  
}

.holzhandel-logo-basket {
  height: 33.99px;
  width: auto
}
.product-menge {
  position: relative;
  padding: 18px 15px 0 15px; 
  display: flex;
  flex-direction: row;
  align-items: center;
  .product-input {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flexstart;
      .menge-div {
          box-sizing: border-box;
          height: 30px;
          width: 52px;
          border: 2px solid #00223B;
          background-color: #FFFFFF;
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
      }
      button {
          height: 28.93px;
          width: 28px;
          background-color: #1A1818;
          border: none !important;
          box-shadow: none !important;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
              width: 10px;
          }
      }
  }
  .product-basket-button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      button {
          background-color: rgba(0,0,0,0.28);
          border: none !important;
          box-shadow: none !important;
          height: 42.9px;
          width: 46px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
              height: 21px;
              width: 22.81px;
          }
      }
      
  }
}

.trash {
  background: transparent;
  border: none;
  box-shadow: none;
  img {
    width: 13.51px;
  }
}
