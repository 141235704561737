.main-navbar {
    .fixed-main-navbar{
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;
    }
    .navbar-brand {
      list-style: none;
      padding: 0;
      img {
        margin-top: -12px;
        height: 30px;
      }
    }
    .navbar {
      height: 72px !important;
      width: 100% !important;
      background: transparent;
      box-shadow: none;
      
      z-index: 1;
      padding-bottom: 0;
      padding-top:0;
      
    }
    .navbar-container {
      padding: 0 50px;
      transition: all .5s ease-in-out;
      &.scroll {
        transition: all .5s ease-in-out;
        background-color: #B73B1E;
        box-shadow: 0 4px 9px 5px rgba(0,0,0,.08);
      }
    }
    .navbar-light .navbar-nav {
        display: table;
        float: right !important;
        height: 40px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        a {
            position: relative;
            width: auto;
            margin: 0 15px;
            color: #F6F8F9;
            padding: 13px 0;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 14px;
            text-align: center;
            &.en-item {
                width: 35px;
                margin-right: 0 !important;
                &::after {
                    background: #ffffff !important;
                    position: absolute;
                    margin: auto;
                    z-index: 1;
                    content: "";
                    width: 2px;
                    height: 16px;
                    top: 10px;
                    right: -2px;
                    opacity: 1;
                }
              /*  &::before {
                    position: absolute;
                    margin: auto;
                    z-index: 1;
                    content: "";
                    bottom: -15px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    height: 17px;
                    width: 0 !important;
                    border-radius: 12px;
                    background-color: #FFC233;
                }*/
            }
            &.de-item {
                width: 35px;
                margin-right: 0;
                margin-left: 0;
                /*&::before {
                    position: absolute;
                    margin: auto;
                    z-index: 1;
                    content: "";
                    bottom: -15px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    height: 17px;
                    width: 0 !important;
                    border-radius: 12px;
                    background-color: #FFC233;
                }*/
            }
            span {
                font-weight: 500!important;
                letter-spacing: 1.6px!important;
            
            }
    
            &:hover {
                font-size: 14px;
                color: #ffffff !important;
                text-shadow: 0px 0px 2px white;
            }
    
            &.active {
                color: #ffffff !important;
                font-size: 14px;
                text-shadow: 0px 0px 2px white;
               /* &::before {
                    position: absolute;
                    margin: auto;
                    z-index: 1;
                    content: "";
                    bottom: -15px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    height: 17px;
                    width: 23.38px;
                    border-radius: 12px;
                    background-color: #FFC233;
                }*/
            }
            
        }
        .basket-button {
          background-color: transparent;
          padding: 0;
          margin: 0 0 0 20px;
          box-shadow: none;
          border: none;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          padding: 10px 0;
          .basket-icon {
            margin-top: -6px;
            height: 20px;
            width: auto;
          }
          .basket-badge {
            position: absolute;
            right: -8px;
            top: -4px;
            z-index: 10;
            box-shadow: none!important;
            border-radius: 50%!important;
            padding: 0 0 0 0!important;
            background-color: #000000!important;
            color: #ffffff!important;
            margin: 0;
            width: 15px;
            height:15px;
            font-size: 10px;
            font-weight: bold;
            line-height: 13px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        
    }

    .sidenav-bottom-respo {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 3000;
        //background: rgba(145, 231, 225) !important;
        .close-button {
          background-color: transparent;
          padding: 0;
          //margin: 40px 35px 0 0;
          width: auto;
          box-shadow: none!important;
          position: relative;
          img {
            height: 23.69px;
            width: 23.69px;
          }
        }
        .sidenavButton {
          background-color: transparent;
          padding: 0;
          margin: 0;
          width: auto;
          box-shadow: none!important;
          position: relative;
          border-radius: 0;

          &.scroll {
            height: 67.17px;
            width: 67.17px;
            border-radius: 50%;
            background: linear-gradient(224.59deg, #dd8673 0%, #811b04 100%) !important;
            margin-right: -18.8px;
            //margin-top: 17px;
          }

        }
        .title-text span {
          margin: 10px 0;
          color: #FFF;
          text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          //font-family: Manrope;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.38px;
          text-align: center;
        }
       
    }
    .sidenav.sidenav-absolute {
      position: fixed !important;
      height: 100% !important;
      top: 0 !important;
      width: 100% !important;
      background: linear-gradient(224.59deg, #dd8673 0%, #811b04 100%) !important;
    }
    .sidenav-backdrop {
      visibility: hidden;
    }
    .sidenav-menu {
      height: 100%;
      padding: 0;
      position: relative;
      .side-menu-list{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        .basket-button {
          background-color: transparent;
          padding: 0;
          margin: 0 0 0 20px;
          box-shadow: none;
          border: none;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          padding: 10px 0;
          .basket-icon {
            margin-top: -6px;
            height: 20px;
            width: auto;
          }
          .basket-badge {
            position: absolute;
            right: -8px;
            top: -4px;
            z-index: 10;
            box-shadow: none!important;
            border-radius: 50%!important;
            padding: 0 0 0 0!important;
            background-color: #000000!important;
            color: #ffffff!important;
            margin: 0;
            width: 15px;
            height:15px;
            font-size: 10px;
            font-weight: bold;
            line-height: 13px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .sidenav-item {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
            .sidenav-link {
              color: #FFF;
              font-size: 20px;
              font-style: normal;
              line-height: 150%;
              letter-spacing: -0.32px; 
              position: relative;
              width: auto;
              padding: 0 50px;
              justify-content: center;
              opacity: 0.8;
              span {
                  font-weight: normal!important;
                  letter-spacing: 1.6px!important;
              }
      
              &:hover {
                  color: #ffffff !important;
                  text-shadow: 0px 0px 1px white;
                  opacity: 1;
              }
      
              &.active {
                  color: #ffffff !important;
                  text-shadow: 0px 0px 1px white;
                  opacity: 1;
              }
            }
          }
      }
    }
    
    .language-menu-list {
      .sidenav-item {
          height: 60px;
          .sidenav-link {
            color: #FFF;
            font-size: 20px;
            font-style: normal;
            line-height: 150%;
            letter-spacing: -0.32px; 
            position: relative;
            width: 121px;
            margin-right: 30px;
            padding: 0;
            
            &.en-item {
                width: 40px;
                margin-right: 0 !important;
                height: 60px;
                &::after {
                    background: #ffffff !important;
                    position: absolute;
                    margin: auto;
                    z-index: 1;
                    content: "";
                    width: 2px;
                    height: 23px;
                    top: 15px;
                    right: 4px;
                    
                }
            }
            &.de-item {
                width: 40px;
                margin-right: 0;
                height: 60px;
                
            }
            span {
                font-weight: 500!important;
                letter-spacing: 1.6px!important;
            
            }
    
            &:hover {
                color: #ffffff !important;
                text-shadow: 0px 0px 1px white;
            }
    
            &.active {
                color: #ffffff !important;
                text-shadow: 0px 0px 1px white;
            }
          }
        }
    }
    .sidenav-menu-respo {
      display: flex;
      align-items: center;
      justify-content:space-between;
      padding: 0 15px;
      height: 80px;
        &.detailpage {
          background: linear-gradient(224.59deg, #dd8673 0%, #811b04 100%) !important
        }
    }
   
}


  