

.detail-section {
    margin: 160px 0; 
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    @media (max-width: 991.98px) {
        flex-direction: column;
        justify-content: flex-start;
        margin: 100px 0 50px 0; 
    }
    .product-detail {
        display: flex;
        flex-direction: row;
        width: 75%;
        @media (max-width: 991.98px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            padding-left: 15px;
        }
        
        img {
            height: 340px;
            margin-left: 70px;
            margin-right: 54px;
            @media (max-width: 991.98px) {
                margin-left: 0;
                padding-right: 15px;
                width: 100%
            }
        }
        .product-content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            @media (max-width: 991.98px) {
                justify-content: flex-start;
                align-items: flex-start;
                padding-top: 30px;
            }
            .product-title {
                text-align: center;
                max-width: 400px;
                color: #000000;
                font-size: 24px;
                letter-spacing: 0.28px;
                line-height: 30px;
                margin-bottom: 20px;
            }
            .product-infos {
                max-width: 306px;
                color: #000000;
                font-size: 16px;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 24px;
                margin-bottom: 20px;
            }
            .product-price-div {
                @media (max-width: 991.98px) {
                    display: flex;
                    justify-content: flex-start;
                }
                .euro {
                    height: 42px;
                    max-width: 18px;
                    color: #1B2437;
                    font-size: 24px;
                    letter-spacing: 0;
                    line-height: 29px;
                    margin-right: 6px;
                }
                .product-price {
                    height: 42px;
                    max-width: 214px;
                    color: #1B2437;
                    font-size: 36px;
                    letter-spacing: 0.4px;
                    line-height: 29px;
                }
                .menge-type {
                    height: 42px;
                    max-width: 114px;
                    color: #1B2437;
                    font-size: 24px;
                    letter-spacing: 0.4px;
                    line-height: 29px;
                }
            }
        }
    }
    .product-menge {
        position: relative;
        padding: 0 15px 0 15px; 
        width: 25%;
        @media (max-width: 991.98px) {
            width: 100%;
            padding: 0 15px 0 15px; 
        }
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin-right: 25px;
        .product-input {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flexstart;
            input {
                box-sizing: border-box;
                height: 42.9px;
                width: 110px;
                border: 2px solid #00223B;
                background-color: #FFFFFF;
                margin: 0 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }
            button {
                height: 28.93px;
                width: 28px;
                background-color: #1A1818;
                border: none !important;
                box-shadow: none !important;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 10px;
                }
            }
        }
        .product-basket-button {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            button {
                background-color: rgba(0,0,0,0.28);
                transition: all .5s ease-in-out;
                border: none !important;
                box-shadow: none !important;
                height: 42.9px;
                width: 46px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    height: 21px;
                    width: 22.81px;
                }
            }
            &.active{
                button {
                    background-color: rgba(0,0,0);
                }
            }
        }
    }
}

.similar-items {
    background-color: #F8F4F4 !important;
    padding: 0 50px 50px 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
    @media (max-width: 991.98px) {
        padding: 30px 20px;
    }
    .carousel-container {
        height: 500px;
        .react-multi-carousel-item {
            display: flex;
            justify-content: center;
            .similar-product-card {
                height: 324px;
                background-color: #FFFFFF;
                max-width: 500px;
                cursor: pointer;
                @media (max-width: 991.98px) {
                    max-width: 400px;
                }
                .product-image-wrapper {
                    img {
                        height: 226.09px;
                        object-fit: cover;
                        width: 100%
                    }
                }
                .product-title {
                    padding-left: 32px;
                    height: 98.36px;
                    display: flex;
                    align-items: center;
                    span {
                            height: 60px;
                            max-width: 304px;
                            color: #000000;
                            font-size: 24px;
                            letter-spacing: 0.28px;
                            line-height: 30px;
                    }
                }
            }
        }
        .react-multiple-carousel__arrow {
            bottom: 0;
        }
        .react-multiple-carousel__arrow--left {
            left: 45%;
            transform: translateX(-50%);
            height: 28px;
            width: 28px;
            border-radius: 0;
            background-color: #1A1818;
            opacity: 0.5;
            transition: opacity .5s ease-in-out;
            &:hover {
                opacity: 1;
                transition: opacity .5s ease-in-out;
            }
        }
        .react-multiple-carousel__arrow--right {
            right: 45%;
            transform: translateX(50%);
            height: 28px;
            width: 28px;
            border-radius: 0;
            background-color: #1A1818;
            opacity: 0.5;
            transition: opacity .5s ease-in-out;
            &:hover {
                opacity: 1;
                transition: opacity .5s ease-in-out;
            }
          }
    }
   
}