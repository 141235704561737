.hero {
    position: relative;
    .backgroundImage-style {
        width: 100%; 
        height: auto; 
        object-fit: cover;
        z-index: 0;
        max-width: 100vw;
        min-height: 100vh;
    }
    .logo-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 496px;
        width: 80%;
    }
}

.about-us {
    margin: 60px 0; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title-text {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        span {
            height: 37px;
            max-width: 410px;
            color: #00223B;
            font-size: 30px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 37px;
            text-align: center;
        }
    }
    .description-text {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        span {
            height: 18px;
            max-width: 1165px;
            color: #00223B;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 18px;
            text-align: center;
        }
    }
}

.catalog {
    background-color: #F8F4F4 !important;
    padding: 0 116px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 991.98px) {
        padding: 0 20px;
    }
    .title-text {
        padding: 88px 0 30px 0;
        display: flex;
        justify-content: center;
        span {
            max-width: 410px;
            color: #1A1818;
            font-size: 30px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 40px;
            text-align: center;
        }
    }
}