@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:wght@400;500;700');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;700&display=swap');



.noselect {
  
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

body {
  background: #ffffff;
  min-height: 100vh;
  font-family: 'Josefin Sans';
  overflow-x: hidden !important;
}

html {
	height: 100vh;
  scroll-behavior: smooth;
}







//pages
@import "pages/homepage.scss";
@import "pages/detailpage.scss";
@import "header/navbar.scss";
@import "footer/footer.scss";
@import "cards/productcard.scss";
@import "gallery/gallery.scss";

@import "buttons/buttons.scss";
@import "basket/basket.scss";



.scrollbar-container {
  height: 100vh !important;
}

.modal-header {
  display: flex;
  flex-direction: column;
}

.modal {
  z-index: 3000;
}