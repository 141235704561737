.loadmore-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 44px;
        width: 165px;
        background-color: #000000;
        border: none;
        box-shadow: none;
        span {
            color: #F8F4F4;
            font-size: 18px;
            letter-spacing: 0.21px;
            line-height: 22px;
        }
    }
}

.btn-close-basket {
    height: 37px;
    width: 37px;
    padding: 0;
    border: none;
    box-shadow: none;
    img {
        height: 37px;
        width: 37px;
    }
}
