.template-footer {
    //height: 565px;
    width: 100%;
    justify-content: center;
    
    .bg-2 {
      height: 260px;
      width: 100%;
      border-radius: 16px;
      background-color: #FFFFFF;
      margin: 0;
      padding: 0 113.5px;
      @media (max-width: 991.98px) {
        height: auto;
        padding: 0;
      }
      .left-col-footer {
        border-right: 1px solid #8E8E93;
        padding: 0 55px;
        .holzhandel-icon-row {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          @media (max-width: 991.98px) {
            padding-top: 25px;
            justify-content: center;
          }
          .holzhandel-icon-footer {
            height: auto;
            width: 258px;
            @media (max-width: 991.98px) {
              width: 200px;
            }
          }
        }
       
        .holzhandel-footer-text-left {
          width: 267px;
          color: #8E8E93;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 24px;
          display:inline-flex;
          @media (max-width: 991.98px) {
            width: auto;
            margin-bottom: 25px;
            text-align: center;
            padding: 0 15px;
          }
        }
        @media (max-width: 991.98px) {
          border-right: none;
          padding: 0 0;
        }
      }
      .middle-col-footer {
        padding-left: 60px;
        @media (max-width: 991.98px) {
          padding: 0;
          text-align: center;
        }
      }
      .right-col-footer {
        padding-left: 30px;
        @media (max-width: 991.98px) {
          padding-left: 0;
          text-align: center;
          padding-bottom: 25px;
        }
      }
      .modal-show-btn {
        margin: 0;
        padding-bottom: 8px;
        text-transform:uppercase;
        background: transparent;
        box-shadow: none;
        border: none;
        span {
          height: 19px;
          max-width: 339.33px;
          color: #1A1818;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 19px;
  
        }
        &:hover {
            text-shadow: 0px 0px 1px black;
        }
      }
    }
  }

  .black-footer {
    height: 72px;
    width: 100%;
    background-color: #1A1818;
    padding: 0 113.5px;
    @media (max-width: 991.98px) {
      padding: 0;
    }
    .black-footer-left-text {
      height: 16px;
      max-width: 303px;
      color: rgba(255,255,255,0.47);
      font-size: 16px;
      letter-spacing: 0;
      line-height: 16px;
    }
    .language-col-left {
      border-right: 1px solid #FFFFFF;
      span {
        height: 19px;
        width: 25px;
        color: #FFFFFF;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
        opacity: 0.9;
        &:hover {
          opacity: 1;
          text-shadow: 0px 0px 1px white;
        }
        &.active {
          opacity: 1;
          text-shadow: 0px 0px 1px white;
        }
      }
    }
    .language-col-right {
      span {
        height: 19px;
        width: 25px;
        color: #FFFFFF;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
        opacity: 0.9;
        &:hover {
          opacity: 1;
          text-shadow: 0px 0px 1px white;
        }
        &.active {
          opacity: 1;
          text-shadow: 0px 0px 1px white;
        }
      }
    }
  }